/* Bounce horizontally (left to right) */
@keyframes bounce-x {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); /* Move right */
    }
  }
  
  .animate-bounce-x {
    animation: bounce-x 10s infinite ease-in-out;
  }
  
  /* Bounce vertically (up and down) */
  @keyframes bounce-y {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(55px); /* Move up */
    }
  }
  
  .animate-bounce-y {
    animation: bounce-y 10s infinite ease-in-out;
  }
  
  /* Bounce diagonally (left-up to right-down) */
  @keyframes bounce-diagonal {
    0%, 100% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(15px, 45px); /* Move diagonally */
    }
  }
  
  .animate-bounce-diagonal {
    animation: bounce-diagonal 10s infinite ease-in-out;
  }
  
  /* Up and down movement */
  @keyframes bounce-up-down {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-35px); /* Move up */
    }
  }
  
  .animate-bounce-up-down {
    animation: bounce-up-down 10s infinite ease-in-out;
  }
  
  /* Bounce right to left */
  @keyframes bounce-right-left {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-76px); /* Move left */
    }
  }
  
  .animate-bounce-right-left {
    animation: bounce-right-left 10s infinite ease-in-out;
  }
  
  /* Move in random directions with ease */
  @keyframes bounce-all-directions {
    0% {
      transform: translate(0, 0); /* Start position */
    }
    25% {
      transform: translate(20px, 60px); /* Move diagonally */
    }
    50% {
      transform: translate(-20px, -30px); /* Move diagonally */
    }
    75% {
      transform: translate(15px, -5px); /* Move diagonally */
    }
    100% {
      transform: translate(0, 0); /* Return to start */
    }
  }
  
  .animate-bounce-all {
    animation: bounce-all-directions 10s infinite ease-in-out;
  }
  