@keyframes bounce-arrow {
    0%, 100% {
      transform: translateX(0); /* Starting and ending position */
    }
    50% {
      transform: translateX(5px); /* Move the arrow forward slightly */
    }
  }
  
  .animate-bounce-arrow {
    animation: bounce-arrow 0.5s infinite ease-in-out; /* Infinite animation */
  }
  